<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-row>
            <b-col class="text-center pb-4">
              <div class="w-25 d-inline-block">
                <CImg fluid src="img/brand/sf-logo.svg" block class="m-2" />
              </div>
            </b-col>
          </b-row>
          <b-card
            img-src="img/login.jpg"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-form class="px-4" @submit.prevent="onSubmit">
              <p class="alert alert-warning" v-if="error_msg">
                {{ error_msg }}
              </p>
              <b-input-group class="mb-3">
                <b-form-input
                  type="text"
                  class="form-control text-center"
                  placeholder="Your email"
                  autocomplete="username email"
                  v-model="username"
                />
              </b-input-group>
              <b-input-group class="mb-4">
                <b-form-input
                  type="password"
                  class="form-control text-center"
                  placeholder="Your password"
                  autocomplete="current-password"
                  v-model="password"
                />
              </b-input-group>
              <b-row>
                <b-col>
                  <b-button
                    variant="outline-primary"
                    block
                    class="text-uppercase font-weight-bold py-2"
                    type="submit"
                    >Log In</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-card>
          <b-row>
            <b-col>
              <router-link to="/forget-password">
                <b-button
                  variant="link"
                  block
                  class="px-0 pt-1 pb-0 text-center font-weight-bold"
                  >Recover password?</b-button
                >
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthModule from "@/store/modules/Auth/AuthModule";

// import {AUTH_LOGIN, AUTH_CHECK_LOGIN} from '@/store/modules';
// console.log(AuthModule)
const defaults = {
  username: null,
  password: null,
};

// TODO. Remove this after initial development
if (process.env.NODE_ENV === "development") {
  defaults.username = `${process.env.VUE_APP_DEV_USERNAME}`;
  defaults.password = `${process.env.VUE_APP_DEV_PASSWORD}`;
}

export default {
  name: "Login",
  data() {
    // const q = this.$route.query;
    return {
      username:
        this.$route.query.email ||
        this.$store.getters.getAuthEmail ||
        defaults.username,
      password: defaults.password,
      code: null,
      error_msg: null,
      submitted: false
    };
  },
  methods: {
    onSubmit: function () {
      this.submitted = true;
      this.signIn();
    },
    signIn: async function () {
      const username = this.username;
      const password = this.password;
      // let vm = this;
      this.error_msg = "";

      AuthModule.logIn({ username, password })
        .then((data) => {
          this.submitted = false;
          if (data.state === "okay" && data.token) {
            this.$router.push("/dashboard");
          } else {
            this.error_msg = "Login failed";
          }
        })
        .catch((err) => {
          console.error(err);
          const error = this.$store.getters.getAuthenticationError;
          if (error) {
            this.error_msg = error;
          } else {
            this.error_msg =
              "Something went wrong please contact an administrator";
          }
        });

      // this.$store.dispatch(AUTH_LOGIN, {vm, username, password}).then(data => {
      //   this.$router.push('/dashboard')
      // }).catch(err => {
      //   console.error(err)
      //   const error = this.$store.getters.getAuthenticationError;
      //   if(error) {
      //     this.error_msg = error
      //   } else {
      //     this.error_msg = 'Something went wrong please contact an administrator';
      //   }
      // });
    },

    toRegister: function () {
      this.$router.push("/register");
    },
  },
  async beforeCreate() {
    // this.$store.dispatch(AUTH_CHECK_LOGIN, {}).then(data => {
    //   this.$router.push('/dashboard');
    // }).catch((e) => {
    //   // console.error(e)
    //   //:: TODO handle check
    // });
  },
};
</script>
